[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.product-slide-entry.shift-image .product-image:hover img {
  transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  -ms-transform: none;
}

header.type-4 .header-top:before {
  content: none;
}

.map-box.type-3 {
  height: 870px;
}

.header-middle {
  padding: 10px 0px;
}

.header-functionality-entry b {
  font-size: 13px;
}
.sale-entry .sale-price span {
  font-size: 32px;
}
.navigation-banner-content {
  height: 310px;
}
.slider_overlay {
  background-color: rgba(75, 45, 10, 0.3);
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #cda02a;
}
.slider_overlay .subtitle,
.slider_overlay .description {
  text-shadow: 1px 1px 2px #646464;
}

.search-field {
  border: 1px #e8e8e8 solid;
  margin-right: 45px;
}

.header-wrapper.style-14 .menu-button {
  color: #747474;
}

.header-top-entry .title {
  cursor: default;
}

.footer-links {
  margin-top: 20px;
}

.header-middle .right-entries {
  width: auto;
}

.header-functionality-entry.open-search-popup .fa,
.header-functionality-entry.portal-link .fa,
.header-functionality-entry.open-cart-popup .fa {
  top: 0;
}

.footer-bottom-navigation {
  padding: 10px 0px;
}

.active_breadcrumb_link:hover {
  cursor: default;
}

.payment-methods a:hover {
  opacity: 1;
  cursor: default;
}

/************ Style 16 new color *****************/
#trans-loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
body.style-16 .swiper-tabs .block-title:before {
  background: #cda02a;
}
body.style-16 .sidebar-navigation .title {
  background: #cda02a;
}
body.style-16 .sidebar-navigation {
  border-color: #cda02a;
}
body.style-16 .sidebar-navigation .entry:before {
  background: #cda02a;
}
body.style-16 .price .current {
  color: #cda02a;
}
body.style-16 #loader-wrapper .bubbles span {
  background: #cda02a;
}
body.style-16 #trans-loader-wrapper .bubbles span {
  background: #cda02a;
}
body.style-16 .product-slide-entry .title:hover {
  color: #cda02a;
}
body.style-16 .swiper-active-switch {
  background: #cda02a;
  border-color: #cda02a;
}
body.style-16 .read-more:hover {
  color: #cda02a;
}
body.style-16 .hover-class-1:after {
  background: #cda02a;
}
body.style-16 .product-slide-entry .subtitle:hover {
  color: #cda02a;
}
body.style-16 .inline-product-entry .image:hover {
  border-color: #cda02a;
}
body.style-16 .inline-product-entry .title:hover {
  color: #cda02a;
}
.header-wrapper.style-16 header .icon-entry:hover .image {
  background: #cda02a;
}
body.style-16 .cart-box.popup .content .price {
  color: #cda02a;
}
body.style-16 .cart-box.popup .summary .grandtotal span {
  color: #cda02a;
}
body.style-16 .button.style-4 {
  background: #cda02a;
  border-color: #cda02a;
}
body.style-16 .sale-entry .sale-price span {
  color: #cda02a;
}
.blog-entry .readmore:hover {
  color: #cda02a;
  border-color: #cda02a;
}
.blog-entry .subtitle a b {
  color: #cda02a;
}
.blog-entry a.title:hover {
  color: #cda02a;
}
.blog-entry .date span {
  background: #cda02a;
}
.article-container a {
  color: #cda02a;
  font-weight: bold;
}
.blog-entry .subtitle {
  color: #cda02a;
  font-weight: bold;
}
.quantity-selector .entry.number-minus:hover,
.quantity-selector .entry.number-plus:hover {
  background: #cda02a;
}
.button.style-10:hover,
.button.style-12:hover {
  background: #cda02a;
  color: #fff;
  border-color: #cda02a;
}
/*********** End ***************/

.product-image .left-align {
  margin-right: 0px;
}

.footer-address a:hover {
  color: #cda02a !important;
}

.product-image .bottom-line-a:hover {
  background: #cda02a;
  color: #fff;
  border-color: transparent !important;
}

.quantity-selector .entry.number {
  width: 44px !important;
  height: 40px !important;
}

.header-top-entry {
  width: 50%;
}

.open-cart-popup {
  padding-right: 0px;
}

.cart-buttons .column {
  width: 100%;
}

.toast-title {
  margin-bottom: 10px;
}

#toast-container > div {
  opacity: 1;
}

.cart-box .content a,
.cart-box .content a:hover {
  color: #cda02a;
}

.top_cart_qty {
  color: #646464;
  font-size: 12px;
}

.add_to_cart_btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.add_to_cart_btn:disabled:hover {
  background-color: #ccc;
  border-color: #262626 !important;
}

input.entry.error {
  border: 1px solid #ca1515;
  z-index: 1;
}

.checkout-title {
  font-size: 16px;
  line-height: 40px;
  text-transform: none;
  padding: 10px 0;
  border-bottom: 1px #cda02a solid;
  margin-top: -1px;
  font-weight: 600;
  color: #cda02a;
  border-top: 1px #cda02a solid;
  background: #f7f7f7;
}

.checkout-title .number {
  display: inline-block;
  width: 40px;
  line-height: 40px;
  background: #f7f7f7;
  text-align: center;
  font-size: 18px;
  color: #cda02a;
  font-weight: 600;
  margin-right: 18px;
}

.checkout-entry {
  padding: 25px 0px;
}

.simple-field {
  margin-bottom: 10px !important;
}

.req_star {
  color: #ca1515;
}

.cart-table td {
  padding: 10px;
}

.traditional-cart-entry .cell-view {
  height: 45px;
}

.traditional-cart-entry .title:hover {
  color: #cda02a;
}

.cart-table th {
  padding: 15px;
  color: #2e2e2e;
}

.help-block.error {
  margin-top: -5px;
  color: #ca1515;
  margin-bottom: 7px !important;
}

input.error,
textarea.error {
  border: 1px solid #ca1515;
}

.uppercase {
  text-transform: uppercase;
}

.traditional-cart-entry .title {
  font-size: 18px;
  color: #2e2e2e;
  font-weight: 500;
}

.cart-table .subtotal {
  font-size: 18px;
  font-weight: 500;
}

.article-container ul.uib-datepicker-popup li:before {
  content: none;
}

table {
  outline-width: 0;
  outline: none;
}

.simple-field {
  box-shadow: none;
  border-radius: 0px;
  outline: none;
}

.simple-field:not(.error):focus {
  outline: none;
  border: 1px #cda02a solid;
  box-shadow: none;
}

.simple-drop-down:not(.error) select:focus {
  outline: none;
  border: 1px #cda02a solid;
  box-shadow: none;
}

.uib-datepicker table th,
.uib-datepicker table td,
.uib-datepicker table tr {
  vertical-align: top;
}

/*********************** AJAX Loader **************************/

.ajax_loader {
  display: table;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.6);
}

.ajax_loader .middle {
  display: table-cell;
  vertical-align: middle;
}

.ajax_loader .inner {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
}

.ajax_loader .inner .spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.ajax_loader .inner .spinner > div {
  width: 18px;
  height: 18px;
  background-color: #d3a42b;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ajax_loader .inner .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.ajax_loader .inner .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*********************** End AJAX Loader **************************/

.get_quote:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.get_quote:disabled:hover {
  background-color: #ccc;
  border-color: #262626 !important;
}
.get_quote {
  width: 100%;
}

.padding-zero {
  padding: 0px !important;
}
.gold {
  color: #cda02a !important;
}
.button.style-10.add_to_cart_btn,
.button.style-10.get_quote {
  background: #cda02a;
  border-color: #cda02a;
}

.button.style-10.add_to_cart_btn:hover,
.button.style-10.get_quote:hover {
  background: transparent !important;
  color: #333232 !important;
}

.f_weight_500 {
  font-weight: 500 !important;
}

#detailsForm .information-entry:nth-child(odd),
#billingForm .information-entry:nth-child(odd),
#deliveryForm .information-entry:nth-child(odd),
#collectionForm .information-entry:nth-child(odd) {
  clear: left;
}

.cart_btn_container {
  width: 100%;
  height: 40px;
}
.add_to_quote_small_btn {
  color: #ffffff !important;
  border: 2px solid #cda02a;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #cda02a;
}

.add_to_quote_small_btn:hover {
  color: #cda02a !important;
  background-color: #fff !important;
}

.latest-review .text {
  border: 2px #f2f2f2 solid;
}

#content-block .latest-review footer {
  color: #cda02a;
}

.blog-landing-box.type-4.columns-3 .blog-entry .content {
  margin-left: 0px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #cda02a;
  border-color: #cda02a;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #cda02a;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #cda02a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination {
  margin: 0;
}

.header-functionality-entry {
  border: none;
}

.article_additional_image_entry {
  margin-bottom: 15px;
}

.offer_img_wrapper img {
  opacity: 0.5;
}

.gold_bold {
  color: #cda02a !important;
  font-weight: bold;
}

.open-weekly-discount-list:hover {
  cursor: pointer;
}

.cart-column-title {
  border-top: none;
}

.discount_red {
  color: #d14242;
}

.offer_red {
  color: #d14242;
}
a:hover .offer_red {
  color: white !important;
}

.shop-grid-controls {
  margin-right: 0px;
}

.category_title {
  border-right: none !important;
  color: #3d3d3d;
  font-size: 30px;
  font-weight: 400;
}

.home_h2_container {
  padding-bottom: 15px;
}

.home_h2_a {
  font-size: 150%;
  font-weight: bold;
}

.home_h2 {
  display: inline;
  font-size: 19px;
}

.product_h2 {
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  color: #808080 !important;
  display: inline;
}

.footer_h3 {
  display: inline;
}

.sidebar-navigation {
  margin-bottom: 5px !important;
}

.img_download_link {
  width: 100%;
  padding: 20px;
}

.submenu {
  -webkit-box-shadow: 2px 6px 10px 0px rgba(136, 136, 136, 0.5);
  -moz-box-shadow: 2px 6px 10px 0px rgba(136, 136, 136, 0.5);
  box-shadow: 2px 6px 10px 0px rgba(136, 136, 136, 0.5);
}
/*.price .current {
		width: 100%;
}*/
.top_social {
  font-size: 17px;
}
.top_social a:hover i {
  color: #cda02a;
}
.action_btns {
  margin: 0px 15px 0px 0px;
}
#printable_quote {
  display: none;
}
.swiper-container.blockquote-slider.swiper-swiper-unique-id-4.initialized {
  margin-bottom: 20px !important;
}
.swiper-container .pagination {
  margin: 15px 0 0 0;
}
.footer_paragraph {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  color: #a1a1a1;
  padding: 10px 0px 25px 0px;
  text-align: center;
}
.home_page_cat_image {
  margin-top: 10px;
}
.portfolio-container .portfolio-entry .action-button {
  background-color: #cda02a;
}
.portfolio-entry a.title:hover {
  color: #cda02a;
}
body.style-16 .sidebar-navigation .entry.active:before {
  background: #e8d074 !important;
}
body.style-16 .sidebar-navigation .entry.active .fa {
  color: #fff;
}
body.style-16 .sidebar-navigation .entry:hover:before {
  background: #e8d074 !important;
}
body.style-16 .sidebar-navigation .entry.request:before,
body.style-16 .sidebar-navigation .entry.request {
  background: #ce3256 !important;
  color: #fff !important;
}
body.style-16 .sidebar-navigation .entry.request .fa {
  color: #fff;
}
.sidebar-navigation.home_side_nav .entry,
.sidebar-navigation.category_side_nav .entry {
  padding: 11px 25px;
}
.sidebar-navigation.home_side_nav .title,
.sidebar-navigation.category_side_nav .title {
  padding: 12px 30px;
}
.sidebar-navigation.home_side_nav.request_btn,
.sidebar-navigation.category_side_nav.request_btn {
  border: none;
}
.sidebar-navigation.home_side_nav.request_btn .list,
.sidebar-navigation.category_side_nav.request_btn .list {
  display: block;
}
.product-detail-box .price.detail-info-entry {
  margin-bottom: 10px;
}
.add_chair_msg_price {
  font-size: 24px;
  color: #ce3256;
  margin-bottom: 5px !important;
}
.add_chair_msg_price strong {
  font-weight: bold;
}
.add_chair_msg_callus {
  font-size: 15px;
  color: #ce3256;
  margin-bottom: 15px !important;
}
.quote_btns .button.style-10:hover {
  border: 1px #cda02a solid;
}
.quote_btns .button.style-10 {
  padding: 10px;
  font-size: 12px;
  border: 1px #262626 solid;
}
.quote_btns .button.style-10.order:hover {
  border: 1px #262626 solid;
  background-color: #262626;
}
.quote_btns .button.style-10.order {
  border: 1px #cda02a solid;
  background-color: #cda02a;
  font-size: 20px;
  padding: 20px;
  font-weight: 600;
}
.quote_btns {
  margin-top: 15px;
}

.content-center.fixed-header-margin {
  padding-bottom: 40px;
}
.trans_option_desc {
  font-size: 12px;
  padding-left: 3px;
  color: #cda02a;
  clear: both;
}

.simple-drop-down.simple-field.error select,
.simple-drop-down.simple-field.error:after {
  border: 1px solid #ca1515;
}
.payment_details_cell {
  width: 30%;
}
input.simple-field {
  font-size: 16px;
  font-family: arial;
}
.simple-drop-down select {
  font-size: 16px;
  font-family: arial;
}

.form-control[readonly],
.form-control[readonly]:hover,
.form-control[readonly]:focus {
  background-color: #f7f7f7;
  cursor: not-allowed;
  border: 1px solid rgb(230, 230, 230);
}
.simple-drop-down.simple-field select:disabled {
  background-color: #f7f7f7 !important;
  cursor: not-allowed;
  border: 1px solid rgb(230, 230, 230);
}
.cal_btn {
  height: 45px;
  margin-top: -10px;
}
.footer_social_icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #fff;
  border-radius: 20px;
  color: #cda02a;
  text-align: center;
  padding-top: 4px;
  margin-left: 5px;
}

.footer_social_icon:first-child {
  margin-left: 0px;
}

.footer_social_icon:hover {
  background-color: #cda02a;
  color: #fff;
}

.information-blocks {
  margin-bottom: 0px !important;
}

.top_bar_cart_search {
  text-align: right;
}
.top_bar_tel {
  text-align: left;
}
.header-top {
  height: 50px;
  line-height: 50px;
  margin: 0px -70px;
  padding: 0px 70px;
  border-bottom: 1px #d6d4d4 solid;
}
.header-top a {
  color: #3d3d3d;
}
.top_bar_cart_search .open-cart-popup {
  margin-left: 0px;
}
.modal {
  left: calc(50vw - 300px) !important;
  margin-left: 0px !important;
}

.top_bar_tel a {
  font-size: 18px;
  font-weight: bold;
  font-family: arial;
}

/*.cart-box.popup.ng-scope.active.cart-right{
	top:30px !important;
}*/
.uib-datepicker-popup.dropdown-menu {
  z-index: 6;
}
#loader-wrapper {
  z-index: 9999;
}
.request_callback_button.mobile_ver {
  display: none;
}
#paypal-button {
  margin-top: 15px;
}
.simple-search-form input[type="text"] {
  font-weight: 400;
}
.d_none {
  display: none;
}
.cart-table .remove-button {
  color: #ca1515;
  border: 1px #ca1515 solid;
}
.cart-table .remove-button:hover {
  color: #fff;
}
.checkout_title_bar_hint {
  float: right;
  padding-right: 15px;
  font-size: 14px;
  color: #313131;
}
.vat_inc_price {
  font-size: 20px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
}
.vat_ex_price {
  font-size: 13px !important;
  font-weight: normal;
  color: #2e2e2e !important;
  margin-top: 5px;
}
.product-detail-box .price {
  letter-spacing: 0px;
}
.product-detail-box .price {
  line-height: 24px;
}
.product-description.article-container {
  margin-bottom: 10px;
}
.tech_spec_fits {
  display: block;
  width: auto;
  height: 25px;
  color: #cda02a;
  letter-spacing: 0px;
  line-height: 15px;
  font-family: sans-serif;
  font-size: 12px;
  padding: 5px;
  border-bottom-left-radius: 7px;
  text-align: center;
}
.tech_spec_fits_desktop {
  display: block;
}
.tech_spec_fits_mobile {
  display: none;
}
.blog-landing-box.type-4 .blog-entry {
  width: 25%;
  float: left;
  padding: 0px 5px;
}
.blog-landing-box.type-4 .blog-entry:nth-child(4n + 1) {
  clear: left;
}
.blog-landing-box.type-4 .blog-entry:nth-child(3n + 1) {
  clear: none;
}
.postcode_checker_result {
  padding: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #2ecc71;
  font-size: 16px;
}
#mobile_map {
  margin-top: 15px;
  margin-bottom: 15px !important;
}
nav > ul > li > a,
nav > ul > li > .fa {
  color: #fff;
}

.header-wrapper.style-14 .navigation {
  margin: 0 -70px;
  padding: 0 70px;
  background: #cda02a;
}
header .icon-entry .image {
  background: #cda02a;
}

header .icon-entry .image i {
  color: #fff !important;
}
header .icon-entry {
  width: 33.33%;
}
.icon-entry:hover .image {
  background: #ebebeb !important;
}

.icon-entry:hover .image i {
  color: #cda02a !important;
}
.cart_icon_box {
  width: 100%;
  height: 45px;
  float: left;
  line-height: 45px;
  text-align: center;
  background: #cda02a;
  float: right;
  color: #fff;
}
nav .simple-list .submenu a {
  color: #404040 !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  opacity: 0.7 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.7 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.7 !important;
}

:-ms-input-placeholder {
  opacity: 0.7 !important;
}
header .icon-entry .text {
  font-size: 12px;
}
.cart-box.popup {
  margin-top: 20px;
}
.cart_icon_box i {
  color: #fff !important;
}
.article-container ul li:before,
.breadcrumb-box a:after {
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
.accordeon-title.active:after,
.accordeon-title:after,
.simple-drop-down:after {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
.discount-banner {
  width: auto;
  text-align: center;
  padding: 15px;
  background-color: #ca1515;
  border: none;
  color: #ffffff;
  font-size: 25px;
  font-family: arial;
  margin: 0px -70px;
  margin-top: -25px;
  margin-bottom: 10px !important;
}
/*.modal-backdrop {
    display: block;
    opacity: .2;
}*/

input:disabled {
  pointer-events: none;
}
.checkbox-entry input {
  display: inline-block;
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  margin-right: 10px !important;
  -webkit-appearance: checkbox;
}
.checkbox-entry input + span {
  display: none !important;
}
.calculation_details .panel-default > .panel-heading {
  text-align: right;
  background-color: #f7f7f7;
  padding: 20px;
  color: #cda02a;
}

.calculation_details .panel-default {
  border-color: #cda02a;
}
.help-block.error {
  color: red;
}
.add_to_quote_small_btn:disabled,
.add_to_quote_small_btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.nav-overflow ul li a:hover,
.nav-overflow ul li a:focus,
.nav-overflow ul li:hover i {
  color: #ca1515;
}
.navigation {
  height: 60px;
}
header.type-4 .header-simple-search {
  padding-top: 10px;
}
nav > ul > li > a {
  line-height: 50px;
}

.simple-search-form .simple-submit {
  color: #656161;
}
input::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
input::-moz-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
input::-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
.home_left .desc {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 25px;
}
.home_left .desc strong {
  font-weight: bold;
}
.home_left h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.block-title {
  font-size: 23px;
  color: #cda02a;
  margin-top: 6px;
}

.navigation {
  height: 100%;
}
.lead_time {
  color: red;
  font-size: 11px;
}
.navigation-banner-content .cell-view {
  width: 500px;
}

/*=============================================
=            Responsive Fixes                 =
=============================================*/

/******************* Xtra Xtra small *************/
@media screen and (max-width: 349px) {
  .top_bar_tel a {
    font-size: 14px !important;
  }
}

@media (max-width: 574px) {
  .or_request_callback {
    display: none !important;
  }
  .top_bar_tel {
    width: 50% !important;
  }
  .top_bar_cart_search {
    width: 50% !important;
  }
  .request_callback_button.mobile_ver {
    display: none !important;
  }
}
@media screen and (max-width: 499px) {
  .header-wrapper.style-14 .navigation {
    margin: 0;
    padding: 0;
    background: #cda02a;
  }
  .request_callback_button.mobile_ver {
    display: block;
  }
  .modal {
    left: calc(50vw - 150px) !important;
  }
  .or_request_callback {
    display: none;
  }
  .call_us_on {
    display: none;
  }
  .menu-button {
    position: relative;
    display: inline-block;
    top: inherit;
    margin-right: 7px;
  }
  .header-top {
    position: fixed;
    background-color: #fff;
    width: 100%;
    left: 0;
    padding: 0px 15px;
    z-index: 7;
  }
  .header-middle {
    margin-top: 55px;
  }
  .top_bar_email {
    display: none;
  }
  .top_bar_tel {
    width: 55%;
    padding-right: 0;
  }
  .top_bar_cart_search {
    width: 45%;
  }

  .search-box.popup.active {
    right: 5px !important;
  }

  .top_bar_tel a {
    font-size: 18px;
    font-family: arial;
  }
  header.type-4 .header-top-entry {
    color: #747474;
  }
  #content-block .navigation-banner-content .button {
    float: none;
  }
  .logo-wrapper {
    display: block;
    width: 100%;
    text-align: center;
  }

  .comm_block {
    width: 90%;
  }
  .top_icons_block {
    margin-bottom: 10px;
  }

  .header-functionality-entry.open-search-popup .fa,
  .header-functionality-entry.facebook-link .fa,
  .header-functionality-entry.twitter-link .fa {
    line-height: 46px;
    margin-bottom: 15px;
  }
  #content-block header {
    position: absolute;
  }
  #content-block .navigation-banner-content .title {
    width: 100%;
    display: block;
    clear: left;
  }
  .footer-columns-entry {
    padding: 15px 15px;
  }
  .header_call_us_on {
    display: none;
  }

  .breadcrumb-box {
    margin-bottom: 5px;
  }
  .blog-sidebar {
    padding-top: 0px;
  }
  body.style-16 .sidebar-navigation .entry.active {
    background: #e8d074 !important;
    color: #fff !important;
  }
  .checkout_title_bar_hint {
    display: none;
  }
  .tech_spec_fits {
    height: 35px;
  }
  .tech_spec_fits_desktop {
    display: none;
  }
  .tech_spec_fits_mobile {
    display: block;
  }
  .blog-landing-box.type-4 .blog-entry {
    width: 50%;
    float: left;
    padding: 0px 5px;
  }
  .blog-landing-box.type-4 .blog-entry:nth-child(2n + 1) {
    clear: left;
  }
  .header-wrapper.style-14 .navigation {
    margin: 0px;
    padding: 0px;
  }
  .top_widgets,
  .middle_cart {
    display: none;
  }
  .footer-logo {
    margin: 10px auto;
  }
  .footer_details {
    text-align: center;
  }
  .main_slider_block {
    margin: 0px -15px;
  }
  #added_to_cart_popup {
    width: 100%;
    left: auto !important;
    margin-left: 0px !important;
  }
  .traditional-cart-entry .image {
    display: none;
  }
  .cart-table th:first-child {
    padding-left: 0px !important;
  }
  .traditional-cart-entry .content {
    margin-left: 0px;
  }
  .cart-table {
    min-width: 343px;
  }
  header .icon-entry .text b,
  header .icon-entry span {
    font-size: 11px;
  }
  header .icon-entry .image {
    width: 30px;
    height: 30px;
  }

  #consent_detailed_modal label {
    font-size: 14px;
  }
  #consent_detailed_modal .consent_sub_txt {
    font-size: 12px;
  }

  .attributes_titles {
    line-height: 16px;
    font-size: 17px !important;
  }
  .privacy_setting_btn {
    display: none;
  }
  .discount-banner {
    font-size: 14px;
    padding: 15px;
  }

  .flip_divs {
    display: flex;
    flex-direction: column-reverse;
  }
  .header-top {
    margin: auto;
  }
}

/* Responsive Typography */

@media (max-width: 767px) {
  .cart-table .subtotal,
  .cart-table td,
  .traditional-cart-entry .title {
    font-size: 14px;
  }
  .number-minus,
  .number-plus {
    display: none !important;
  }
  #detailsForm *,
  .information-blocks {
    font-size: 14px !important;
  }
  .home_left .desc {
    font-size: 14px !important;
  }
  .block-title {
    font-size: 18px !important;
  }
  .home_left h2 {
    font-size: 14px !important;
  }
  .pros-header {
    font-size: 26px !important;
  }

  .side_widget_title {
    font-size: 18px !important;
  }
  .pros-unit p {
    font-size: 14px !important;
  }
  .product-slide-entry .title {
    font-size: 13px !important;
  }
  .vat_inc_price {
    font-size: 16px !important;
  }
  .duration {
    font-size: 15px !important;
  }
  .track-order-button {
    margin-bottom: 2em !important;
  }
  .track-order-input-div {
    margin-bottom: 0 !important;
  }
  .track-order-heading {
    text-align: center !important;
  }
  .track-order-ref,
  .track-order-p {
    text-align: left !important;
  }
}

/****************** xtra small ***************/

@media (min-width: 500px) and (max-width: 767px) {
  .top_bar_tel a {
    font-size: 17px;
  }
  .privacy_setting_btn {
    display: none;
  }
  .modal {
    left: calc(50vw - 150px) !important;
  }
  .call_us_on {
    display: none;
  }
  .menu-button {
    position: relative;
    display: inline-block;
    top: inherit;
    margin-right: 7px;
  }
  .header-top {
    position: fixed;
    background-color: #fff;
    width: 100%;
    left: 0;
    padding: 0px 15px;
  }
  .header-middle {
    margin-top: 55px;
  }
  .top_bar_email {
    display: none;
  }
  .top_bar_tel {
    width: 60%;
  }
  .top_bar_cart_search {
    width: 40%;
  }

  .search-box.popup.active {
    right: 5px !important;
  }

  header.type-4 .header-top-entry {
    color: #747474;
  }

  .comm_block {
    width: 90%;
  }
  .top_icons_block {
    margin-bottom: 10px;
  }
  #content-block .navigation-banner-content .button {
    float: none;
  }
  .logo-wrapper {
    display: block;
    width: 100%;
    text-align: center;
  }

  .header-functionality-entry.open-search-popup .fa,
  .header-functionality-entry.open-cart-popup .fa,
  .header-functionality-entry.portal-link .fa,
  .header-functionality-entry.facebook-link .fa,
  .header-functionality-entry.twitter-link .fa {
    line-height: 46px;
    margin-bottom: 15px !important;
  }

  #content-block header {
    position: absolute;
  }

  #content-block .navigation-banner-content .title {
    width: 100%;
    display: block;
  }
  .footer-columns-entry {
    padding: 15px 15px;
  }

  .breadcrumb-box {
    margin-bottom: 5px;
  }
  .blog-sidebar {
    padding-top: 0px;
  }
  body.style-16 .sidebar-navigation .entry.active {
    background: #e8d074 !important;
    color: #fff !important;
  }
  .checkout_title_bar_hint {
    display: none;
  }
  .blog-landing-box.type-4 .blog-entry {
    width: 33.33% !important;
    float: left;
    padding: 0px 5px;
  }
  .blog-landing-box.type-4 .blog-entry:nth-child(3n + 1) {
    clear: left !important;
  }
  .blog-landing-box.type-4.columns-3 .blog-entry:nth-child(2n + 1) {
    clear: none;
  }
  .header-wrapper.style-14 .navigation {
    margin: 0px;
    padding: 0px;
  }
  .main_slider_block {
    margin: 0px -15px;
  }

  #added_to_cart_popup {
    width: 100%;
    left: auto !important;
    margin-left: 0px !important;
  }
  .traditional-cart-entry .image {
    display: none;
  }
  .cart-table th:first-child {
    padding-left: 0px !important;
  }
  .traditional-cart-entry .content {
    margin-left: 0px;
  }
  .cart-table {
    min-width: 343px;
  }
  header {
    padding: 0 30px;
  }
  header .icon-entry .image {
    width: 30px;
    height: 30px;
  }
  .header-top {
    margin: auto;
  }
}

/********************* small **************/

@media (min-width: 768px) and (max-width: 991px) {
  .header-top {
    margin: auto;
    padding: 0px !important;
  }
  .consent_body {
    font-size: 11px;
  }
  header.type-4 .header-top-entry {
    color: #747474;
  }
  .call_us_on {
    display: none;
  }
  .menu-button {
    position: relative;
    display: inline-block;
    top: inherit;
    margin-right: 7px;
  }

  #content-block header {
    position: absolute;
  }
  #content-block .navigation-banner-content .title {
    width: 100%;
    display: block;
  }
  .breadcrumb-box {
    margin-bottom: 5px;
  }
  .blog-sidebar {
    padding-top: 0px;
  }
  body.style-16 .sidebar-navigation .entry.active {
    background: #e8d074 !important;
    color: #fff !important;
  }
  /* .header-wrapper.style-14 .navigation{
			margin: 0px;
			padding: 0px;
	} */
  .main_slider_block {
    margin: 0px -30px;
  }
  header {
    padding: 0 30px;
  }

  .header-functionality-entry.open-search-popup {
    display: inline-block;
  }
  .header-functionality-entry span {
    display: none;
  }
  .header-functionality-entry.open-cart-popup .fa {
    line-height: 46px;
    margin-bottom: 15px;
    top: 0;
  }
  .header-functionality-entry .fa {
    width: 33px;
    height: 33px;
    font-size: 23px;
    color: #2f2f2f;
    text-align: center;
  }
}

/**************** medium ****************/

@media (min-width: 992px) and (max-width: 1199px) {
  .call_us_on {
    display: none;
  }
  .menu-button {
    position: relative;
    display: inline-block;
    top: inherit;
    margin-right: 7px;
  }

  header.type-4 .header-top-entry {
    color: #747474;
  }

  #content-block .navigation-banner-content .title {
    width: 100%;
    display: block;
  }
  body.style-16 .sidebar-navigation .entry.active {
    background: #e8d074 !important;
    color: #fff !important;
  }
  /*.header-wrapper.style-14 .navigation{
			margin: 0px;
			padding: 0px;
	}*/
  .header-middle {
    border-bottom: 2px solid #cda02a;
  }
  .main_slider_block {
    margin: 0px -30px;
  }
  .header-middle {
    border-bottom: 0px;
  }

  .header-functionality-entry.open-search-popup {
    display: inline-block;
  }
  .header-functionality-entry span {
    display: none;
  }
  .header-functionality-entry.open-cart-popup .fa {
    line-height: 46px;
    margin-bottom: 15px;
    top: 0;
  }
  .header-functionality-entry .fa {
    width: 33px;
    height: 33px;
    font-size: 23px;
    color: #2f2f2f;
    text-align: center;
  }
}

/************ Large **************/

@media (min-width: 1200px) {
  .fa.fa-phone.visible-lg,
  .open-cart-popup.visible-lg {
    display: inline-block !important;
  }
  header.type-4 .header-functionality-entry,
  header.type-4 .header-top-entry.hidden-xs a,
  header.type-4 .header-top-entry,
  header.type-4 .header-top-entry .title .fa,
  header.type-4 .header-middle .open-cart-popup .fa,
  header.type-4 .header-functionality-entry b {
    color: #747474;
  }

  header.type-4 .header-top-entry .title {
    border: none;
  }

  header.type-4 .header-functionality-entry:hover,
  header.type-4 .header-top-entry.hidden-xs a:hover,
  header.type-4 .header-top-entry:hover,
  header.type-4 .header-top-entry .title .fa:hover,
  header.type-4 .header-middle .open-cart-popup .fa:hover,
  header.type-4 .header-functionality-entry b:hover {
    color: #3d3d3d;
  }

  header.type-4 .header-middle .open-cart-popup:before {
    content: none;
  }

  .nav_search {
    padding-right: 105px;
  }
  .open-cart-popup {
    float: right;
  }

  header.type-4 .header-middle .open-cart-popup {
    padding: 0px 15px;
  }
  header.fixed-header .nav-overflow {
    background: #cda02a;
    border-bottom: 2px #cda02a solid;
  }
  header.fixed-header nav {
    text-align: center;
  }
  header.type-4 .header-middle .open-cart-popup {
    float: right;
    width: 150px;
    margin-top: 25px;
  }

  .main_slider_block {
    margin: 0 -70px;
  }
}

.pros-unit {
  text-align: center;
  margin-bottom: 30px;
}
.pros-header {
  font-size: 32px;
  text-align: center;
  margin-bottom: 10px;
}
.pros-unit img {
  width: 110px;
}
.side_widget_title {
  font-size: 22px;
  text-align: center;
  margin: 5px 0px;
}
.pros-unit p {
  font-size: 16px;
  text-align: center;
}
.duration {
  font-size: 20px;
  font-weight: 500;
}
.also-for-sale {
  font-size: 13px;
  color: #808080;
  font-weight: bold;
}
.also-for-sale i {
  color: #cda02a;
}
.special-offer-sticker {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  width: 55px;
}

span.ico-sale {
  background: #e14141;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  right: 8px;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ico-label span {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#d41010 0%, #d41010 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  /* z-index: 500000000; */
}

/* **********************TRACKING ******************** */

.track_carriage_note {
  padding: 0.7rem 1.3rem;
  border-radius: 5px;
  border: 1px solid #48484a;
  background-color: #cda02a;
  color: white;
}
.track-order-p {
  margin-top: 1em;
  text-align: center;
}

.track-order-input-div {
  margin-bottom: 2em;
  margin-top: 1.5em;
}

.track-order-button {
  margin-top: 1.5em;
}

.track-order-ref {
  margin-bottom: 1em !important;
  margin-top: 1.5em;
  text-align: center;
}

/* ***************** TRACKING SUCCESS ***************** */
.track-order-heading {
  margin-top: 1em;
  margin-bottom: 2em !important;
  text-align: left;
}

.track-order-bar {
  margin-bottom: 6em;
}

.track-order-back-btn {
  margin-bottom: 3em;
}

/********************** TRACKING LABEL ********************** */
#tracking_ref_id-error {
  margin-bottom: -18px !important;
}

/* ******************** SIMILAR SELECT FEATURE ******************/

.product_group_div {
  margin-bottom: 25px !important;
}

.txt-bold-orange {
  color: #cda02a;
  font-weight: bold;
}

.p-tag-similar-product {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
}

.key-features-margin {
  margin-bottom: 25px !important;
}
/* 006A67 */
.btn-vat-custom {
  background-color: #51a351 !important;
  border-color: #51a351 !important;
  color: white;
}
div.toggle.btn.btn-danger.off {
  margin: 8px 0px;
}
div.toggle.btn.btn-vat-custom {
  margin: 8px 0px;
}
@media only screen and (max-width: 767px) {
  .hide-on-mobiles {
    display: none !important;
  }
  .toggle.btn {
    min-width: 65px;
    min-height: 31px;
  }
  .toggle-off.btn {
    padding-left: 20px;
  }
  div.toggle.btn.header-vat {
    height: 31px !important;
    width: 75px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hide-on-mobiles {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hide-on-desktops {
    display: none !important;
  }
  div.toggle.btn.header-vat {
    height: 34px !important;
    width: 90px !important;
  }
}
@media only screen and (min-width: 992px) {
  .hide-on-tablets {
    display: none !important;
  }
  div.toggle.btn.header-vat {
    height: 34px !important;
    width: 90px !important;
  }
}

.btn-sm-vat-custom {
  background-color: #51a351 !important;
  border-color: #51a351 !important;
  color: white;
}
.header-vat {
  font-size: 10px !important;
  padding: 0;
  margin-top: -7px;
  display: inline-block !important;
}

label.btn.btn-danger.active.toggle-off {
  font-size: 12px !important;
}
.overlay-switcher,
#vat-toggle {
  width: 100%;
  height: 100%;
  position: absolute;
  min-width: 97px;
  min-height: 34px;
  margin-top: 7px;
  right: 2px;
}
#vat-toggle {
  z-index: 10;
}
.overlay-switcher {
  z-index: 98;
}
.overlay-switcher-sm,
#vat-toggle-sm {
  width: 100%;
  height: 100%;
  position: absolute;
  width: 59px;
  height: 32px;
  margin-top: 7px;
}
#vat-toggle-sm {
  z-index: 10;
}
.overlay-switcher-sm {
  z-index: 98;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  nav > ul > li {
    float: left;
    padding-right: 5px;
    padding-left: 5px;
  }
}

label.btn.btn-danger.active.toggle-off,
label.btn.btn-vat-custom.toggle-on {
  font-size: 13px !important;
}
@media screen and (max-width: 499px) {
  .header-functionality-entry.open-cart-popup .fa,
  .header-functionality-entry.portal-link .fa,
  .header-functionality-entry.open-search-popup .fa {
    line-height: 46px;
    margin-bottom: 20px !important;
  }
  .header-top-tel-no {
    height: 100%;
    display: inline-block;
    position: relative;
    top: -3px;
  }
}

